import type { Graph } from "schema-dts";

interface PageMeta {
  canonicalUrl: string;
  title: string;
  locale: string;
}

const localeMap = {
  en: "en-US",
  "en-gb": "en-GB",
  de: "de",
};

export const generateSchemaOrgData = (meta: PageMeta): Graph => {
  return {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        "@id": `${meta.canonicalUrl}#webpage`,
        url: meta.canonicalUrl,
        name: meta.title,
        inLanguage: localeMap[meta.locale as keyof typeof localeMap],
        isFamilyFriendly: true,
        schemaVersion: "https://schema.org/version/28.1/",
        isPartOf: {
          "@type": "WebSite",
          name: "TravelLocal",
          url: process.env.NEXT_PUBLIC_WEB_ROOT || "",
        },
        mainEntity: {
          "@type": "TravelAgency",
          "@id": `${process.env.NEXT_PUBLIC_WEB_ROOT}#travelagency`,
        },
      },
      {
        "@type": "Brand",
        name: "TravelLocal",
        slogan:
          meta.locale === "de"
            ? "Weltweite Reisen mit Experten vor Ort"
            : "Global travel, local experts",
        logo: "https://resources.travellocal.com/wp/uploads/2024/07/18123425/TL_logo_Jan2022_accent.png",
      },
    ],
  };
};

import { fetchJson, Locale } from "@travellocal/utils";
import {
  GetArticleBySlugDocument,
  GetArticlesDocument,
  GetCollectionBySlugDocument,
  GetCollectionsDocument,
  GetHomePagesDocument,
  GetHowItWorksDocument,
  GetTagsBySlugDocument,
  GetRequestFormDocument,
  GetRegionProfileDocument,
  GetRegionIdBySlugDocument,
  GetCountriesByRegionDocument,
  GetGlobalContentDocument,
  AllRegionProfilesDocument,
  Maybe,
  GetCountryDocument,
  GetSubscribeDocument,
  GetCountryHeroImageDocument,
  AllRegionsDocument,
  GetRegionProfileQuery,
  TaxQueryOperator,
  GetCollectionBySlugAndLocaleDocument,
} from "./graphql.generated";
import { print } from "graphql";
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
import mergeWith from "lodash/mergeWith";

type fetchCMSVariables = {
  [key: string]: string | string[] | Locale[] | number;
};
const CMS_ENDPOINT = process?.env?.NEXT_PUBLIC_CMS_GRAPHQL ?? "https://cms.travellocal.com/graphql";

async function fetchCMS<TQuery, TVars extends fetchCMSVariables>(
  query: DocumentNode<TQuery, TVars>,
  variables: fetchCMSVariables
) {
  const cmsEndpoint = `${CMS_ENDPOINT}${variables.requestName ? `?${variables.requestName}` : ""}`;
  const { data } = await fetchJson<{ data: TQuery }>(cmsEndpoint, {
    method: "POST",
    body: {
      query: print(query),
      variables,
    },
    credentials: "omit",
  });

  return data;
}

type GraphQLNode<T> = Partial<{
  nodes: Maybe<Maybe<Partial<T>>[]>;
}>;

type ContentNode = {
  locales?: Maybe<GraphQLNode<{ slug: Maybe<string> }>>;
  tags?: Maybe<GraphQLNode<{ slug: Maybe<string> }>>;
};

/**
 * Build the content for the required locale by merging the "base" document with the one for the locale.
 */
function mergeDocuments<T extends GraphQLNode<ContentNode>>(
  targetLocale: string,
  documents?: Maybe<T>
) {
  // The content for our locale is tagged in the locale taxonomy
  const localeContent = documents?.nodes?.find((x) =>
    x?.locales?.nodes?.find((l) => l?.slug === targetLocale)
  );

  if (!localeContent) {
    return null;
  }

  // The base content is tagged with "is-parent"
  const baseContent =
    documents?.nodes?.find((x) => x?.tags?.nodes?.some((tag) => tag?.slug === "is-parent")) ?? {};

  const ignoreNull = <T, U>(a: T, b: U) => (b === null ? a : undefined);
  return mergeWith({}, baseContent, localeContent, ignoreNull) as T["nodes"][0];
}

/**
 * Get data for the homepage.
 */
export const getHomePage = async (locale: Locale, requestName: string) => {
  const data = await fetchCMS(GetHomePagesDocument, { requestName: `${requestName}_${locale}` });
  return mergeDocuments(locale, data?.homePages);
};

/**
 * Get data for the request form.
 */
export const getRequestForm = async (
  country: string = "",
  locale: Locale = "en",
  requestName: string
) => {
  if (!country) {
    return [];
  }
  const data = await fetchCMS(GetRequestFormDocument, {
    country,
    locale,
    requestName: `${requestName}_${locale}`,
  });
  return data?.countryProfiles.nodes;
};

/**
 * Get data for the request form.
 */
export const getCountry = async (country: string, locales: Locale[], requestName: string) => {
  const data = await fetchCMS(GetCountryDocument, {
    country,
    locales,
    requestName: `${requestName}_${locales}`,
  });
  return {
    nodes: data?.countryProfiles?.nodes,
    globalContent: data?.globalContents?.nodes,
  };
};

/**
 * Get country hero image.
 */
export const getCountryHeroImage = async (
  country: string,
  locales: Locale[],
  requestName: string
) => {
  const data = await fetchCMS(GetCountryHeroImageDocument, {
    country,
    locales,
    requestName: `${requestName}_${locales}`,
  });
  return data?.countryProfiles?.nodes?.[0]?.country?.countryheroimage;
};

/**
 * Get a page of articles
 */
export const listArticles = async (
  locales: Locale[],
  tags: string[] = [],
  requestName: string,
  pageCursor?: string,
  count: number = 10,
  notIn: string[] = []
) => {
  const data = await fetchCMS(GetArticlesDocument, {
    locales: locales as string[],
    tags,
    after: pageCursor,
    first: count,
    notIn,
    requestName: `${requestName}_${locales}`,
  });

  return data?.articles;
};

export const getArticle = async (slug: string, locale: Locale, requestName: string) => {
  const data = await fetchCMS(GetArticleBySlugDocument, {
    slug,
    requestName: `${requestName}_${locale}`,
  });

  return data?.article;
};

export const getHowItWorks = async (locales: Locale, requestName: string) => {
  const data = await fetchCMS(GetHowItWorksDocument, { requestName: `${requestName}_${locales}` });

  return data?.howItWorksPages;
};

export const getTags = async (slugs: string[] = [], locale: Locale, requestName: string) => {
  if (!slugs?.length) {
    return [];
  }
  const data = await fetchCMS(GetTagsBySlugDocument, {
    slugs,
    requestName: `${requestName}_${locale}`,
  });
  return data?.tags?.nodes;
};

interface PagerParams {
  pageCursor?: string;
  count: number;
}

interface ListCollectionsParams {
  endPage?: string;
  locales: Locale[];
  notInIds?: string[];
  tags?: string[];
  tagsOperator?: "IN" | "NOT_IN";
}

export const listCollections = async (
  options: ListCollectionsParams & PagerParams,
  requestName: string
) => {
  const defaultTagsOperator: ListCollectionsParams["tagsOperator"] =
    (options.tags ?? []).length > 0 ? "IN" : "NOT_IN";

  const data = await fetchCMS(GetCollectionsDocument, {
    locales: options?.locales,
    tags: options.tags || [],
    notIn: options.notInIds,
    tagsOperator:
      (options.tagsOperator ?? defaultTagsOperator) === "IN"
        ? TaxQueryOperator.In
        : TaxQueryOperator.NotIn,
    after: options.endPage,
    first: options.count,
    requestName: options?.locales ? `${requestName}_${options.locales}` : `${requestName}`,
  });

  return data?.collections;
};

export const getCollection = async (slug: string, locale: Locale, requestName: string) => {
  const data = await fetchCMS(GetCollectionBySlugDocument, {
    slug,
    requestName: `${requestName}_${locale}`,
  });
  return data?.collection;
};

export const getCollectionBySlugAndLocale = async (
  slug: string,
  locale: Locale,
  requestName: string
) => {
  const data = await fetchCMS(GetCollectionBySlugAndLocaleDocument, {
    slug,
    locales: [locale],
    requestName: `${requestName}_${locale}`,
  });
  return data?.collections?.nodes[0];
};

export const getSubscribe = async (locales: string[], requestName: string) => {
  const data = await fetchCMS(GetSubscribeDocument, {
    locales: locales as string[],
    requestName: `${requestName}_${locales}`,
  });

  return Array.isArray(data?.globalContents?.nodes)
    ? {
        subscribeText:
          data.globalContents?.nodes[0].globalContent?.subscribeComponent?.subscribeText ||
          undefined,
        subscribeBodyText:
          data.globalContents?.nodes[0].globalContent?.subscribeComponent?.subscribeBodyText ||
          undefined,
      }
    : {
        subscribeText: null,
        subscribeBodyText: null,
      };
};

/**
 * Get data for the region profile
 */
export const getRegionProfile = async (
  region: string,
  locales: Locale[],
  regionId: string,
  requestName: string
) => {
  if (!region) {
    return [] as GetRegionProfileQuery;
  }
  const data = await fetchCMS(GetRegionProfileDocument, {
    region,
    locales,
    regionId,
    requestName: `${requestName}_${locales}`,
  });
  return data;
};

/**
 * Returns all regions
 */
export const getAllRegions = async (locale: Locale, requestName: string) => {
  const data = await fetchCMS(AllRegionsDocument, { requestName: `${requestName}_${locale}` });
  return data.regions.nodes;
};

/**
 * Returns all region profiles for the given locale
 */
export const getAllRegionProfiles = async (locale: Locale, requestName: string) => {
  const data = await fetchCMS(AllRegionProfilesDocument, {
    locales: [locale],
    requestName: `${requestName}_${locale}`,
  });
  return data.regionProfiles.nodes.sort((a, b) => a.title.localeCompare(b.title));
};

/**
 * Get id for the region
 */
export const getRegionIdFromRegionProfileSlug = async (
  regionProfileSlug: string,
  locale: Locale,
  requestName: string
) => {
  if (!regionProfileSlug) {
    return "";
  }
  const data = await fetchCMS(GetRegionIdBySlugDocument, {
    regionProfileSlug,
    requestName: `${requestName}_${locale}`,
  });
  return data.regionProfiles.nodes[0].regions.nodes[0].id;
};

/**
 * Get countries by the Region they're categorised with
 */
export const getCountriesByRegion = async (
  regionTermIds: string[],
  localeTerms: string[],
  requestName: string
) => {
  const data = await fetchCMS(GetCountriesByRegionDocument, {
    regionTermIds: regionTermIds,
    localeTerms: localeTerms,
    requestName: `${requestName}_${localeTerms}`,
  });

  return data?.countryProfiles;
};

/**
 * Get GlobalContent by locale
 */
export const getGlobalContent = async (localeTerms: string[], requestName: string) => {
  const data = await fetchCMS(GetGlobalContentDocument, {
    localeTerms: localeTerms,
    requestName: `${requestName}_${localeTerms}`,
  });

  return data?.globalContents;
};

import { fetchJson, Locale } from "@travellocal/utils";
import { print } from "graphql";
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
import {
  GetContinentsWithPublishedTripsDocument,
  GetLandingPageByPathDocument,
  GetTripByPathDocument,
  GetLanguageSwitcherPagesDocument,
  GetTripFilterResultByLandingPageAndTripFilterAndStateDocument,
  TripFilterInput,
  TripFilterStateInput,
  GetTripFilterAndStateAndResultByLandingPageAndPathDocument,
  GetTripsByCountrySlugAndFiltersDocument,
  GetFiltersByCountrySlugDocument,
  GetCountryBySlugDocument,
  UpdateTripRequestUserByIdAndTokenDocument,
  CreateOrUpdateInternalTripRequestMessageInputDocument,
  GetTripRequestByIdAndTemporaryTokenDocument,
} from "./graphql.generated";
import flatMap from 'lodash/flatMap';
import omit from 'lodash/omit';
import * as Sentry from "@sentry/nextjs";

export async function fetchSite2<TQuery, TVars>(
  locale: Locale,
  query: DocumentNode<TQuery, TVars>,
  variables: TVars
) {
  if (!process.env.NEXT_PUBLIC_TM_API) {
    throw new Error(
      "NEXT_PUBLIC_TM_API is not set, GraphQL request is not possible."
    );
  }

  const endpoint = `${
    typeof window === "undefined"
      ? process.env.NEXT_PUBLIC_TM_API
      : "/api/site2"
  }/${locale}/graphql/public`;

  const { data } = await fetchJson<{ data: TQuery }>(endpoint, {
    method: "POST",
    body: {
      query: print(query),
      variables,
    },
  });

  return data;
}

type updateTripRequestUserByIdAndTokenType = {
  locale: Locale;
  tripRequestId: string;
  securityToken: string;
  email?: string;
  phone?: string;
};

type createOrUpdateInternalTripRequestMessageType = {
  locale: Locale;
  tripRequestId: string;
  securityToken: string;
  message: string;
};

export const updateTripRequestUserByIdAndToken = async ({
  locale,
  ...rest
}: updateTripRequestUserByIdAndTokenType) => {
  const data = await fetchSite2(
    locale,
    UpdateTripRequestUserByIdAndTokenDocument,
    rest
  );
  return data?.result;
};

export const createOrUpdateInternalTripRequestMessage = async ({
  locale,
  ...rest
}: createOrUpdateInternalTripRequestMessageType) => {
  const data = await fetchSite2(
    locale,
    CreateOrUpdateInternalTripRequestMessageInputDocument,
    rest
  );
  return data?.result;
};

export const getTripRequestByIdAndTemporaryToken = async (
  locale: Locale,
  tripRequestId: string,
  securityToken: string
) => {
  try {
  const data = await fetchSite2(
    locale,
    GetTripRequestByIdAndTemporaryTokenDocument,
    { tripRequestId, securityToken }
  );
  return data?.result;
} catch (e) {
  Sentry.captureMessage(`Something went wrong with fetchSite2 in getTripRequestByIdAndTemporaryToken() for tripRequestId ${tripRequestId}: ${e}`);
  console.log(
    `Error calling fetchSite2 in getTripRequestByIdAndTemporaryToken() for tripRequestId ${tripRequestId}`,
    e
  );
  return null;
}
};

export const getLandingPageByPath = async (locale: Locale, path: string) => {
  try {
    const data = await fetchSite2(locale, GetLandingPageByPathDocument, {
      path,
    });
    return data?.result;
  } catch (e) {
    Sentry.captureMessage(`Something went wrong with fetchSite2 in getLandingPageByPath() for country slug ${path}: ${e}`);
    console.log(
      `Error calling fetchSite2 in getLandingPageByPath() for ${path}`,
      e
    );
  }
  return null;
};

export const getTripByPath = async (locale: Locale, path: string) => {
  const data = await fetchSite2(locale, GetTripByPathDocument, { path });
  return data?.result;
};

export const getTripFilterResultByLandingPageAndTripFilterAndState = async (
  locale: Locale,
  landingPageId: string,
  tripFilter: TripFilterInput,
  tripFilterState: TripFilterStateInput,
  offset?: number,
  limit?: number
) => {
  const data = await fetchSite2(
    locale,
    GetTripFilterResultByLandingPageAndTripFilterAndStateDocument,
    { landingPageId, tripFilter, tripFilterState, limit, offset }
  );
  return data?.result;
};

export const getTripFilterAndStateAndResultByLandingPageAndPath = async (
  locale: Locale,
  landingPageId: string,
  path: string,
  offset?: number,
  limit?: number
) => {
  const data = await fetchSite2(
    locale,
    GetTripFilterAndStateAndResultByLandingPageAndPathDocument,
    { landingPageId, path, offset, limit }
  );
  return data?.result;
};

export const getTripIdeaCardDataByUrl = async (locale: Locale, url: string) => {
  const { pathname } = new URL(url);

  try {
    const tripData = await getTripByPath(locale, pathname);

    return {
      id: tripData.id,
      title: tripData.title,
      imageSrc: tripData.mainPhotoURL,
      href: pathname,
      target: "_self",
      numberOfDays: tripData.amountOfTripDays,
      price: tripData.tripPriceMatrix.relevantMinPrice,
      currency: tripData.tripPriceMatrix.targetCurrency,
      threeStepRF: tripData.linkToThreeStepRequestForm,
      customiseAndRequestTarget: "_self",
    };
  } catch {
    return null;
  }
};

export const getCountriesWithPublishedTrips = async (locale: Locale) => {
  const result = await fetchSite2(
    locale,
    GetContinentsWithPublishedTripsDocument,
    {}
  );

  return flatMap(
    result?.continents ?? [],
    x => x.countries
  ).map(x => omit(x, '__typename'))
  .sort((a, b) => a.name.localeCompare(b.name));
};

export const getLanguageSwitcherPages = async (
  locale: Locale,
  path: string
) => {
  const { result } = await fetchSite2(
    locale,
    GetLanguageSwitcherPagesDocument,
    {
      path,
    }
  );

  return result;
};

export const getTripsByCountrySlugAndFilters = async (
  locale: Locale,
  countrySlug: string,
  filters: string,
  offset?: number,
  limit?: number
) => {
  const data = await fetchSite2(
    locale,
    GetTripsByCountrySlugAndFiltersDocument,
    { countrySlug, filters, limit, offset }
  );
  return data?.result;
};

export const getFiltersByCountrySlug = async (
  locale: Locale,
  countrySlug: string
) => {
  try {
    const data = await fetchSite2(locale, GetFiltersByCountrySlugDocument, {
      countrySlug,
    });
    return data?.result;
  } catch (e) {
    Sentry.captureMessage(`Something went wrong with fetchSite2 in getFiltersByCountrySlug() for country slug ${countrySlug}: ${e}`);
    console.log(
      `Error calling fetchSite2 in getFiltersByCountrySlug() for country slug ${countrySlug}`,
      e
    );
  }
  return null;
};

export const getCountryByCountrySlug = async (
  locale: Locale,
  countrySlug: string
) => {
  try {
    const data = await fetchSite2(locale, GetCountryBySlugDocument, {
      countrySlug,
    });
    return data?.result;
  } catch (e) {
    Sentry.captureMessage(`Something went wrong with fetchSite2 in getCountryByCountrySlug() for country slug ${countrySlug}: ${e}`);
    console.log(
      `Error calling fetchSite2 in getCountryByCountrySlug() for country slug ${countrySlug}`,
      e
    );
  }
  return null;
};
